import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";
import BaseButton from "../../additionalComponents/button";

import Box from "@mui/material/Box";
import useTitle from "../../additionalComponents/titlePage";

function ErrorMassage404() {
  const { t } = useTranslation();
  useTitle(t("title.number"));

  return (
    <div className={styles.wrapper}>
      <Box
        className={styles.box}
        height={466}
        width={517}
        my={4}
        display="flex"
        alignItems="center"
        gap={4}
        p={2}
        sx={{
          background: "linear-gradient(180deg, #FFFFFF 0%, #ECEFF1 100%)",
          border: `1px solid  #F7EF8A`,
          borderRadius: "10px",
          boxShadow: "1px 2px 20px 0px #084fbd40",
        }}
      >
        <div className={styles.container}>
          <div className={styles.numberError}>{t("appErrors.error_code")}</div>
          <h1 className={styles.notFound}>{t("appErrors.not_found")}</h1>
          <div className={styles.errorMessage}>
            {t("appErrors.error_message")}
          </div>
          <a href="/">
            <BaseButton buttonTranslation={"appErrors.back_to_main"} />
          </a>
        </div>
      </Box>
    </div>
  );
}

export default ErrorMassage404;
