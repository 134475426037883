import appErrors from "./errors.json";
import appHeader from "./header.json";
import appFooter from "./footer.json";
import faq from "./faq.json";
import forms from "./forms.json";
import mainPage from "./mainPage.json";
import title from "./title.json";

const translationEN = {
  appErrors,
  appHeader,
  appFooter,
  faq,
  forms,
  mainPage,
  title,
};

export default translationEN;
