import React from "react";
import Box from "@mui/material/Box";

const Card = ({ text, className, styleKey }) => {
  return (
    <Box className={className} key={styleKey}>
      <div className={styleKey}>{text}</div>
    </Box>
  );
};

export default Card;
