import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";
import BaseButton from "../../additionalComponents/button";
import CardRotator from "./averageCard";
import useTitle from "../../additionalComponents/titlePage";

const Invest = () => {
  const { t } = useTranslation();
  useTitle(t("title.titleMainPage"));

  return (
    <div className={styles.container}>
      <div className={styles.containerTextButt}>
        <h1 className={styles.mainTitle}>{t("mainPage.title")}</h1>
        <p className={styles.textUnderTitile}>{t("mainPage.textUnderTitle")}</p>
        <BaseButton buttonTranslation="mainPage.investButton" href="/" />
      </div>
      <div className={styles.containerAvarageCard}>
        <CardRotator />
      </div>
    </div>
  );
};

export default Invest;
