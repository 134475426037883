import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";
import { BoxComponent } from "../../additionalComponents/boxComponent";
import BaseButton from "../../additionalComponents/button";

import { Box } from "@mui/material";

function CongratulationsForm() {
  return (
    <div className={styles.wrapper}>
      <BoxComponent width={505} minHeight={277}>
        <Congratulations />
      </BoxComponent>
    </div>
  );
}

function Congratulations() {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <h1 className={styles.title}>{t("forms.congrat_title")}</h1>
      <a href="/">
        <BaseButton buttonTranslation="forms.return" />
      </a>
    </Box>
  );
}

export default CongratulationsForm;
