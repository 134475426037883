import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import i18n from "./i18n";

import "./colors.variables.css";
import "./normalize.css";
import "./index.css";

import Error404 from "./components/404";
import Header from "./components/header";
import Footer from "./components/footer";
import Faq from "./components/faq";
import LoginForm from "./components/forms/loginForm";
import RegisterForm from "./components/forms/registerForm";
import EnterCodeForm from "./components/forms/enterCodeForm";
import ChangePasswordForm from "./components/forms/changePassword";
import Congratulations from "./components/forms/congratulations";
import MainPage from "./components/mainPage/main";

const App = () => {
  useEffect(() => {
    i18n.changeLanguage("ua");
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/singup" element={<RegisterForm />} />
        <Route path="/verify" element={<EnterCodeForm />} />
        <Route path="/changepass" element={<ChangePasswordForm />} />
        <Route path="/success" element={<Congratulations />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
