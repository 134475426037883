import i18n from "i18next";

import { initReactI18next } from "react-i18next";

import LanguageDetector from "i18next-browser-languagedetector";

import translationRU from "./locales/ru/index.js";
import translationUA from "./locales/ua/index.js";
import translationEn from "./locales/en/index.js";

const availableLanguages = ["ua", "ru", "en"];

const resources = {
  ua: {
    translation: translationUA,
  },
  ru: {
    translation: translationRU,
  },
  en: {
    translation: translationEn,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "ua",
    fallbackLng: "ua",
    whitelist: availableLanguages,
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
