/**
 * Проверяет адрес электронной почты.
 * @param {string} email - Адрес электронной почты для проверки.
 * @returns {boolean} - true, если адрес электронной почты является действительным, false в противном случае.
 */
export const validateEmail = (email) => {
  if (email.trim() === "") {
    return false;
  } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(email)) {
    return false;
  }
  return true;
};

// /**
//  * Возвращает сообщение об ошибке для недействительного адреса электронной почты.
//  * @param {string} email - Адрес электронной почты.
//  * @param {(function|string)} t - Функция перевода или строка для получения сообщений об ошибках.
//  * @returns {?string} - Сообщение об ошибке, если адрес электронной почты недействителен, null в противном случае.
//  */
// export const getEmailError = (email, t = (key) => key) => {
//   if (!validateEmail(email)) {
//     if (email.trim() === "") {
//       return t("forms.email_required");
//     } else {
//       return t("forms.email_invalid");
//     }
//   }
//   return null;
// };

/**
 * Валидирует пароль.
 * @param {string} password - Пароль для валидации.
 * @returns {boolean} - true, если пароль является действительным, false в противном случае.
 */
export const validatePassword = (password) => {
  if (password.trim() === "") {
    return false;
  } else if (password.length < 7) {
    return false;
  }
  return true;
};

// /**
//  * Возвращает сообщение об ошибке для недействительного пароля.
//  * @param {string} password - Пароль.
//  * @param {(function|string)} t - Функция перевода или строка для получения сообщений об ошибках.
//  * @returns {?string} - Сообщение об ошибке, если пароль недействителен, null в противном случае.
//  */
// export const getPasswordError = (password, t = (key) => key) => {
//   if (!validatePassword(password)) {
//     if (password.trim() === "") {
//       return t("forms.password_required");
//     } else {
//       return t("forms.warning");
//     }
//   }
//   return null;
// };

/**
 * Валидирует повторенный пароль.
 * @param {string} passwordRepeat - Повторенный пароль для валидации.
 * @param {string} password - Оригинальный пароль для сравнения.
 * @returns {boolean} - true, если повторенный пароль является действительным, false в противном случае.
 */
export const validatePasswordRepeat = (passwordRepeat, password) => {
  if (passwordRepeat.trim() === "") {
    return false;
  } else if (passwordRepeat.length < 7) {
    return false;
  } else if (passwordRepeat !== password) {
    return false;
  }
  return true;
};

// /**
//  * Возвращает сообщение об ошибке для недействительного повторенного пароля.
//  * @param {string} passwordRepeat - Повторенный пароль.
//  * @param {string} password - Оригинальный пароль для сравнения.
//  * @param {(function|string)} t - Функция перевода или строка для получения сообщений об ошибках.
//  * @returns {?string} - Сообщение об ошибке, если повторенный пароль недействителен, null в противном случае.
//  */
// export const getPasswordRepeatError = (
//   passwordRepeat,
//   password,
//   t = (key) => key
// ) => {
//   if (!validatePasswordRepeat(passwordRepeat, password)) {
//     if (passwordRepeat.trim() === "") {
//       return t("forms.password_required");
//     } else if (passwordRepeat.length < 7) {
//       return t("forms.warning");
//     } else {
//       return t("forms.passwords_do_not_match");
//     }
//   }
//   return null;
// };
