// FaqPage.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

// Импорт элементов accordions и темы
import { faqItems1, faqItems2 } from "../../additionalComponents/accordions";
import { theme } from "../../additionalComponents/theme";
import useTitle from "../../additionalComponents/titlePage";
import styles from "./index.module.css";

// Импорт компонентов Material UI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BaseButton from "../../additionalComponents/button";

// Группа кнопок для FAQ
const FaqButtonGroup = ({ onContainedButtonClick, onTextButtonClick }) => {
  const buttons = [
    {
      buttonTranslation: "faq.buttonCourses",
      onClick: onContainedButtonClick,
    },
    {
      buttonTranslation: "faq.buttonFund",
      onClick: onTextButtonClick,
    },
  ];

  const buttonElements = buttons.map((buttonConfig, index) => (
    <BaseButton key={index} {...buttonConfig} />
  ));

  return <div className={styles.buttonGroup}>{buttonElements}</div>;
};

// Элемент аккордеона
const AccordionItem = ({ accordion, expanded, handleExpansion }) => {
  const { t } = useTranslation();
  const backgroundColor =
    expanded === accordion.id ? "var(--text-stroke-color)" : "inherit";
  return (
    <div key={accordion.id} className={styles.accordionItem}>
      <Accordion
        expanded={expanded === accordion.id}
        onChange={() => handleExpansion(accordion.id)}
        sx={{ backgroundColor }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${accordion.id}-content`}
          id={`panel${accordion.id}-header`}
        >
          <Typography sx={theme.typography}>{t(accordion.title)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={theme.typography}>{t(accordion.content)}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

// Список аккордеонов
const AccordionList = ({ items = [] }) => {
  const [expanded, setExpanded] = useState(null);

  const handleExpansion = (id) => {
    setExpanded(expanded === id ? null : id);
  };
  const accordionComponents = items.map((accordion) => {
    return (
      <AccordionItem
        key={accordion.id}
        accordion={accordion}
        expanded={expanded}
        handleExpansion={handleExpansion}
      />
    );
  });

  return <div className={styles.accordionItems}>{accordionComponents}</div>;
};

// Страница FAQ
const FaqPage = () => {
  const { t } = useTranslation();
  useTitle(t("title.FAQ"));
  const [faqItems, setFaqItems] = useState(faqItems1);

  const handleButtonClick = (newFaqItems) => {
    setFaqItems(newFaqItems);
  };

  const handleContainedButtonClick = () => {
    handleButtonClick(faqItems1);
  };

  const handleTextButtonClick = () => {
    handleButtonClick(faqItems2);
  };

  return (
    <div className={styles.accordion}>
      <h1 className={styles.titleMain}>{t("faq.app_title")}</h1>
      <FaqButtonGroup
        onContainedButtonClick={handleContainedButtonClick}
        onTextButtonClick={handleTextButtonClick}
      />
      <AccordionList items={faqItems} />
    </div>
  );
};

export default FaqPage;
