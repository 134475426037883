import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import styles from "./index.module.css";

function WeAreInNumbers() {
  const { t } = useTranslation();

  // Подготовка данных о текстах
  const cardsData = [
    { num: "100k $", text: t("mainPage.weAreInNumbersText1") },
    { num: "100k $", text: t("mainPage.weAreInNumbersText2") },
    { num: t("mainPage.month"), text: t("mainPage.weAreInNumbersText3") },
    { num: "100", text: t("mainPage.weAreInNumbersText4") },
    {
      num: t("mainPage.5%_y_$"),
      text: t("mainPage.weAreInNumbersText5"),
    },
    {
      num: t("mainPage.60%_y_$"),
      text: t("mainPage.weAreInNumbersText6"),
    },
  ];

  // Подготовка компонентов карточек
  const cards = cardsData.map((item, index) => (
    <Box className={styles.cardBox} key={index}>
      <div className={styles.cardNum}>{item.num}</div>
      <div className={styles.cardText}>{item.text}</div>
    </Box>
  ));

  return (
    <div>
      <h2>{t("mainPage.weAreInNumbersTitle")}</h2>
      <div className={styles.cardsContainer}>{cards}</div>
    </div>
  );
}

export default WeAreInNumbers;
