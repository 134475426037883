import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";
import Card from "../../additionalComponents/card";

function InvestmentSecurity() {
  const { t } = useTranslation();

  // Подготовка данных о текстах
  const securityTexts = [
    t("mainPage.investmentSecurityText1"),
    t("mainPage.investmentSecurityText2"),
    t("mainPage.investmentSecurityText3"),
    t("mainPage.investmentSecurityText4"),
    t("mainPage.investmentSecurityText5"),
    t("mainPage.investmentSecurityText6"),
  ];

  // Подготовка компонентов карточек
  const cards = securityTexts.map((text, index) => (
    <Card
      text={text}
      className={styles.cardBox}
      styleKey={styles.cardInvestText}
      key={index}
    />
  ));

  return (
    <div>
      <h2 className={styles.investmentSecurityTitle}>
        {t("mainPage.investmentSecurityTitle")}
      </h2>
      <div className={styles.cardsContainer}>{cards}</div>
    </div>
  );
}

export default InvestmentSecurity;
