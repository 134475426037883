import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

import { BoxComponent } from "../../additionalComponents/boxComponent";
import BaseButton from "../../additionalComponents/button";

import { Box, Link, TextField } from "@mui/material";

function EnterCodeForm() {
  return (
    <div className={styles.wrapper}>
      <BoxComponent width={495} minHeight={479}>
        <EnterCode />
      </BoxComponent>
    </div>
  );
}

function EnterCode() {
  const { t } = useTranslation();
  const [inpValue, setValue] = useState("");
  const [error, setError] = useState(null);

  const handleBlur = (e) => {
    // Проверяем, является ли поле ввода пустым
    if (e.target.value === "") {
      // Если поле пустое, устанавливаем сообщение об ошибке
      setError(t("forms.enter_code_requred"));
      // Сообщение об ошибке устанавливается как "Введите код"
    } else if (!/^\d+$/.test(e.target.value)) {
      // Если поле не пустое, проверяем, содержит ли вводимое значение только цифры
      // с помощью регулярного выражения. Если нет, устанавливаем сообщение об ошибке.
      setError(t("forms.enter_code_requred"));
    } else if (e.target.value.length < 5) {
      // Если вводимое значение содержит только цифры, проверяем, имеет ли оно длину не менее 5 символов
      // Если нет, устанавливаем сообщение об ошибке.
      setError(t("forms.enter_code_requred"));
    } else {
      // Если все вышеуказанные условия ложны, значит вводимое значение является корректным
      // Поэтому очищаем любое существующее сообщение об ошибке
      setError(null);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setValue(value);
    setError(null);
  };

  // Функция для валидации кода
  const validateCode = (code) => {
    // Если длина кода меньше 1 символа, возвращаем false
    if (code.length < 1) return false;
    // Если код не состоит только из цифр, возвращаем false
    if (!/^\d+$/.test(code)) return false;
    // Если длина кода меньше 5 символов, возвращаем false
    if (code.length < 5) return false;
    // Если код прошел все проверки, возвращаем true
    return true;
  };

  // Функция для обработки события отправки формы
  const handleSubmit = (event) => {
    // Предотвращаем отправку формы по умолчанию
    event.preventDefault();
    // Валидируем код с помощью функции validateCode
    const isValid = validateCode(inpValue);
    // Если код не валиден, выводим ошибку
    if (!isValid) {
      setError(t("forms.enter_code_requred"));
    } else {
      // Если код валиден, выводим сообщение в консоль
      console.log("Введен код: ", inpValue);
    }
  };

  const hasError = error !== null;

  return (
    <Box
      width="100%"
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <h1 className={styles.title}>{t("forms.enterCode_title")}</h1>
      <div className={styles.haveSend}>{t("forms.we_haveSend")}</div>
      <TextField
        className={styles.noSpinner}
        error={hasError}
        onBlur={handleBlur}
        id="enter_code"
        label={t("forms.enter_code")}
        type=""
        variant="outlined"
        fullWidth
        value={inpValue}
        onChange={handleInputChange}
        margin="normal"
        helperText={error}
        inputProps={{
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
      <BaseButton type="submit" buttonTranslation="forms.further" href="/" />
      <CreateAccountLink />
    </Box>
  );
}

function CreateAccountLink() {
  const { t } = useTranslation();
  return (
    <div className={styles.resendCode}>
      <Link href="/">{t("forms.resend")}</Link>
    </div>
  );
}

export default EnterCodeForm;
