import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

import BaseButton from "../../additionalComponents/button";
import Card from "../../additionalComponents/card";

function UnderstandMarket() {
  const { t } = useTranslation();

  // Подготовка данных о текстах
  const marketTexts = [
    t("mainPage.understandMarketText1"),
    t("mainPage.understandMarketText2"),
    t("mainPage.understandMarketText3"),
    t("mainPage.understandMarketText4"),
    t("mainPage.understandMarketText5"),
    t("mainPage.understandMarketText6"),
    t("mainPage.understandMarketText7"),
    t("mainPage.understandMarketText8"),
  ];

  // Подготовка компонентов карточек

  const cards = marketTexts.map((text, index) => (
    <Card
      text={text}
      className={styles.cardBoxUnderstandMarket}
      styleKey={styles.cardTextUnderstandMarket}
      key={index}
    />
  ));

  return (
    <div>
      <h2 className={styles.understandMarketTitle}>
        {t("mainPage.understandMarketTitle")}
      </h2>
      <div className={styles.understandMarketTitleUnderText}>
        {t("mainPage.understandMarketTitleUnderText")}
      </div>
      <div className={styles.cardsContainerUnderstandMarket}>{cards}</div>
      <BaseButton buttonTranslation="mainPage.understandButton" href="/" />
    </div>
  );
}

export default UnderstandMarket;
