import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import styles from "./index.module.css";

import { BoxComponent } from "../../additionalComponents/boxComponent";
import BaseButton from "../../additionalComponents/button";
import useTitle from "../../additionalComponents/titlePage";
import {
  getEmailError,
  getPasswordError,
  getPasswordRepeatError,
} from "../../validatorComponents/getErrors";

import { Box, TextField, Checkbox, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Это основной компонент формы входа
function RegisterForm() {
  return (
    <div className={styles.wrapper}>
      <BoxComponent width={428} height={600}>
        <Register />
      </BoxComponent>
    </div>
  );
}

// Это функция, возвращающая контент формы входа
function Register() {
  const { t } = useTranslation();
  useTitle(t("title.registerForms"));

  //Мы создаем объект state с начальными значениями, которые будут изменяться в зависимости от действий пользователя.
  const [state, setState] = useState({
    email: "",
    password: "",
    passwordRepeat: "",
    showPassword: false,
    showPasswordRepeat: false,
    iRead: false,
    iReadWarning: false,
    emailError: null,
    passwordError: null,
    passwordRepeatError: null,
    iReadError: null,
    sty: undefined,
  });

  // Разбирает объект состояния на отдельные переменные
  const {
    email,
    password,
    passwordRepeat,
    showPassword,
    showPasswordRepeat,
    iRead,
    iReadWarning,
    emailError,
    passwordError,
    passwordRepeatError,
    sty,
  } = state;

  // Обновляет состояние, объединяя новые значения с предыдущим состоянием
  const updateState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  // Обрабатываем изменения в полях электронной почты или пароля
  const handleFieldChange = (e, type) => {
    updateState(type, e.target.value);
    updateState(`${type}Error`, null);
  };

  // Обрабатываем событие потери фокуса для полей электронной почты или пароля
  const handleFieldBlur = (e, type) => {
    if (type === "password") {
      const passwordError = getPasswordError(e.target.value, t);
      updateState("passwordError", passwordError);
    } else if (type === "email") {
      const emailError = getEmailError(e.target.value, t);
      updateState("emailError", emailError);
    } else if (type === "passwordRepeat") {
      const passwordRepeatError = getPasswordRepeatError(
        e.target.value,
        password,
        t
      );
      updateState("passwordRepeatError", passwordRepeatError);
    }
  };

  // Переключаем видимость пароля
  const handleTogglePasswordVisibility = (type) => {
    updateState(type, !state[type]);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleTogglePassword = (fieldId) => {
    handleTogglePasswordVisibility(
      fieldId === "password" ? "showPassword" : "showPasswordRepeat"
    );
  };

  // Обрабатывает изменения в чекбоксе "Я прочитал"
  const handleIReadChange = (event) => {
    updateState("iRead", event.target.checked);
    if (!event.target.checked) {
      updateState("iReadWarning", true);
      updateState("sty", styles.test);
    } else {
      updateState("iReadWarning", false);
      updateState("sty", undefined);
    }
  };

  // Обрабатывает отправку формы
  const handleSubmit = (event) => {
    // Предотвращает стандартное поведение отправки формы
    event.preventDefault();

    // Инициализирует флаг, отслеживающий валидность формы
    let isValid = true;

    // Проверяет поле электронной почты
    const emailError = getEmailError(email, t);
    if (emailError) {
      // Если электронная почта невалидна, обновляет состояние с ошибкой
      updateState("emailError", emailError);
      // Устанавливает флаг isValid в false
      isValid = false;
    } else {
      // Если электронная почта валидна, очищает ошибку
      updateState("emailError", null);
    }

    // Проверяет поле пароля
    const passwordError = getPasswordError(password, t);
    if (passwordError) {
      // Если пароль невалиден, обновляет состояние с ошибкой
      updateState("passwordError", passwordError);
      // Устанавливает флаг isValid в false
      isValid = false;
    } else {
      // Если пароль валиден, очищает ошибку
      updateState("passwordError", null);
    }

    // Проверяет поле повторного ввода пароля
    const passwordRepeatError = getPasswordRepeatError(
      passwordRepeat,
      password,
      t
    );
    if (passwordRepeatError) {
      // Если повторный ввод пароля невалиден, обновляет состояние с ошибкой
      updateState("passwordRepeatError", passwordRepeatError);
      // Устанавливает флаг isValid в false
      isValid = false;
    } else {
      // Если повторный ввод пароля валиден, очищает ошибку
      updateState("passwordRepeatError", null);
    }

    // Проверяет, совпадают ли поля пароля и повторного ввода пароля
    if (password !== passwordRepeat) {
      // Если они не совпадают, обновляет состояние с ошибкой
      updateState("passwordRepeatError", t("forms.passwords_do_not_match"));
      // Устанавливает флаг isValid в false
      isValid = false;
    }

    // Проверяет, отмечен ли чекбокс "Я прочитал"
    if (!iRead) {
      // Если он не отмечен, обновляет состояние с ошибкой
      updateState("iReadError", t("forms.remember_me_required"));
      // Устанавливает флаг isValid в false
      isValid = false;
    } else {
      // Если он отмечен, очищает ошибку
      updateState("iReadError", null);
    }

    // Если форма валидна, выводит сообщение об успехе в консоль
    if (isValid) {
      console.log(
        "Форма валидна, отправка...",
        email,
        password,
        passwordRepeat,
        iRead
      );
    }
  };

  // Обработчик фокуса на чекбоксе "Я прочитал"
  const handleIReadFocus = () => {
    if (!iRead) {
      updateState("sty", undefined);
    }
  };

  // Обработчик потери фокуса на чекбоксе "Я прочитал"
  const handleIReadBlur = () => {
    if (!iRead) {
      updateState("sty", styles.test);
    }
  };

  const emError = emailError !== null;

  // Объект, содержащий обработчики событий для поля электронной почты
  const emailHandlers = {
    // Обработчик изменения значения поля электронной почты
    onChange: (e) => handleFieldChange(e, "email"),
    // Обработчик потери фокуса поля электронной почты
    onBlur: (e) => handleFieldBlur(e, "email"),
    // Обработчик получения фокуса поля электронной почты
    onFocus: () => updateState("emailError", null),
  };

  // Массив объектов, описывающих поля ввода пароля и повторного ввода пароля
  const passwordFields = [
    {
      // Первое поле - ввод пароля
      id: "password",
      label: t("forms.password"), // Метка для поля (например, "Пароль")
      type: showPassword ? "text" : "password", // Тип поля (текст или пароль) в зависимости от значения showPassword
      icon: showPassword ? <VisibilityOff /> : <Visibility />, // Иконка для поля (отображение/скрытие пароля)
      onChange: (e) => handleFieldChange(e, "password"), // Обработчик изменения значения поля
      onBlur: (e) => handleFieldBlur(e, "password"), // Обработчик потери фокуса поля
      onFocus: () => updateState("passwordError", null), // Обработчик получения фокуса поля (очищает ошибку)
      error: passwordError, // Ошибка для поля (например, если пароль не соответствует требованиям)
    },
    {
      // Второе поле - повторный ввод пароля
      id: "passwordRepeat",
      label: t("forms.password_repeat"), // Метка для поля (например, "Повторите пароль")
      type: showPasswordRepeat ? "text" : "password", // Тип поля (текст или пароль) в зависимости от значения showPasswordRepeat
      icon: showPasswordRepeat ? <VisibilityOff /> : <Visibility />, // Иконка для поля (отображение/скрытие пароля)
      onChange: (e) => handleFieldChange(e, "passwordRepeat"), // Обработчик изменения значения поля
      onBlur: (e) => handleFieldBlur(e, "passwordRepeat"), // Обработчик потери фокуса поля
      onFocus: () => updateState("passwordRepeatError", null), // Обработчик получения фокуса поля (очищает ошибку)
      error: passwordRepeatError, // Ошибка для поля (например, если пароли не совпадают)
    },
  ];

  // Создаем функцию-обработчик клика, которая будет вызывать handleTogglePassword
  // с указанным fieldId, когда будет кликнута
  const handleClick = (fieldId) => () => handleTogglePassword(fieldId);

  const fieldsComponents = passwordFields.map((field, index) => (
    <TextField
      key={index}
      {...field}
      error={!!field.error}
      helperText={field.error}
      fullWidth
      margin="normal"
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClick(field.id)}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {field.icon}
          </IconButton>
        ),
      }}
    />
  ));

  const isButtonDisabled = !!(
    !iRead ||
    emailError ||
    passwordError ||
    passwordRepeatError
  );

  return (
    <Box
      width="100%"
      component="form"
      noValidate
      autoComplete="on"
      onSubmit={handleSubmit}
    >
      <h1 className={styles.title}>{t("forms.register_title")}</h1>
      <TextField
        error={emError}
        id="email"
        label={t("forms.email")}
        variant="outlined"
        fullWidth
        margin="normal"
        {...emailHandlers}
        helperText={emailError}
      />
      {fieldsComponents}
      <Box>
        <div className={styles.checkbox}>
          <Checkbox
            checked={iRead}
            className={sty}
            sx={{
              padding: 0,
            }}
            onChange={handleIReadChange}
            onFocus={handleIReadFocus}
            onBlur={handleIReadBlur}
          >
            {t("forms.remember_me")}
          </Checkbox>
          {iReadWarning && (
            <span className={styles.warning}>
              {t("forms.remember_me_required")}
            </span>
          )}
          <span className={styles.inscriptionSpam}>{t("forms.agree")}</span>
        </div>
        <PrivacyPolicy />
      </Box>
      <BaseButton
        type="submit"
        disabled={isButtonDisabled}
        buttonTranslation="forms.register"
        href="/enterCodeForm"
      />
      <CreateAccountLink />
    </Box>
  );
}
// Это функция, возвращающая ссылку на политику конфиденциальности
function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <div className={styles.privacy}>
      <Link to="/">{t("forms.privacy")}</Link>;
    </div>
  );
}
// Это функция, возвращающая ссылку на регистрацию
function CreateAccountLink() {
  const { t } = useTranslation();
  return (
    <div className={styles.loginCreateAccount}>
      <div>{t("forms.have_account")}</div>
      <div className={styles.accountLink}>
        <Link to="/login">{t("forms.enter")}</Link>
      </div>
    </div>
  );
}

export default RegisterForm;
