import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, FormControl, Select, Box } from "@mui/material";

import styles from "./index.module.css";

const languages = [
  { value: "ua", label: "UA" },
  { value: "ru", label: "RU" },
  { value: "en", label: "EN" },
];

const languageOptions = languages.map((language) => (
  <MenuItem key={language.value} value={language.value}>
    <span className={styles.spanLang}>{language.label}</span>
  </MenuItem>
));

const LanguageSelect = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className={styles.headerLang}>
      <Box sx={{ width: 70 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            value={i18n.language}
            onChange={handleChange}
          >
            {languageOptions}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default LanguageSelect;
