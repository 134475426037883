import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

function HistoryOfTheLeader() {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className={styles.historyOfTheLeader}>
        {t("mainPage.historyOfTheLeaderTitle")}
      </h2>
      <div className={styles.historyOfTheLeaderUnderText}>
        {t("mainPage.historyOfTheLeaderUnderText")}
      </div>
      <div>
        <img src="./icons_svg/history.svg" alt="/" />
      </div>
    </div>
  );
}

export default HistoryOfTheLeader;
