// Footer.js
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";
import { paymentSystems, socialLinks, linksNav, links } from "./footer.data";
import useAuth from "../../additionalComponents/useAuth";

const Footer = () => {
  const { t } = useTranslation();
  const isLoggedIn = useAuth();

  const handleLoginCheck = (href) => {
    if (!isLoggedIn) {
      return "/login";
    }
    return href;
  };

  const linkItems = links.map((link) => (
    <li key={link.text}>
      <a href={handleLoginCheck(link.href)}>{t(link.text)}</a>
    </li>
  ));

  const linkNavItems = linksNav.map((link) => (
    <li key={link.text}>
      <a href={link.href}>{t(link.text)}</a>
    </li>
  ));

  const paymentSystemItems = paymentSystems.map((system, index) => (
    <img key={index} src={system.src} alt={system.alt} />
  ));

  const socialLinkItems = socialLinks.map((link, index) => (
    <a key={index} href="/">
      <img src={link.src} alt={link.alt} />
    </a>
  ));

  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.containerLogo}>
          <div>
            <a href="/">
              <img src="./icons_svg/logo.svg" alt="TRADE NEST" />
            </a>
          </div>
          <div className={styles.logoText}>{t("appFooter.tagline")}</div>
          <div className={styles.logoActions}>{paymentSystemItems}</div>
        </div>
        <div className={styles.containerLink}>
          <ul>{linkItems}</ul>
        </div>
        <div className={styles.containerLinkNav}>
          <ul>{linkNavItems}</ul>
        </div>
        <div>
          <div className={styles.containerAddress}>
            <div>{t("appFooter.address")}</div>
            <div>+380 000000000</div>
            <div>SUPPORT@TRADENEST.NET</div>
            <div className={styles.containerSocial}>{socialLinkItems}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
