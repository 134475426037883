export function generateData() {
  // Создаем пустой массив для хранения месяцев
  const months = [];

  // Определяем массив имен серий
  const seriesNames = ["seriesA", "seriesB", "seriesC"];

  // Создаем пустой объект для хранения данных
  const data = {};

  // Инициализируем объект данных пустыми массивами для каждой серии
  seriesNames.forEach((series) => (data[series] = []));

  // Цикл по последним 12 месяцам
  for (let i = 0; i < 12; i++) {
    // Создаем новый объект даты для текущего месяца
    const date = new Date(
      new Date().getFullYear(), // текущий год
      new Date().getMonth() - i, // текущий месяц минус i
      1 // день месяца (1-е число)
    );

    // Добавляем дату в массив месяцев
    months.push(date);

    // Цикл по каждой серии и добавляем случайное значение в массив данных
    seriesNames.forEach((series) => data[series].push(Math.random() * 10 - 5));
  }

  // Возвращаем объект с месяцами и данными
  return { months, data };
}
