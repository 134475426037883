import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

import { BoxComponent } from "../../additionalComponents/boxComponent";
import BaseButton from "../../additionalComponents/button";
import {
  getPasswordError,
  getPasswordRepeatError,
} from "../../validatorComponents/getErrors";

import { Box, TextField, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Это основной компонент формы входа
function ChangePasswordForm() {
  return (
    <div className={styles.wrapper}>
      <BoxComponent width={495} minHeight={500}>
        <ChangePassword />
      </BoxComponent>
    </div>
  );
}

// Это функция, возвращающая контент формы входа
function ChangePassword() {
  const { t } = useTranslation();

  //Мы создаем объект state с начальными значениями, которые будут изменяться в зависимости от действий пользователя.
  const [state, setState] = useState({
    password: "",
    passwordRepeat: "",
    showPassword: false,
    showPasswordRepeat: false,
    passwordError: null,
    passwordRepeatError: null,
    isButtonDisabled: true,
  });

  // Разбирает объект состояния на отдельные переменные
  const {
    password,
    passwordRepeat,
    showPassword,
    showPasswordRepeat,
    passwordError,
    passwordRepeatError,
    isButtonDisabled,
  } = state;

  // Обновляет состояние, объединяя новые значения с предыдущим состоянием
  const updateState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  // Валидируем поле пароля в зависимости от типа
  const validateField = (type, value, password) =>
    ({
      password: getPasswordError(value, t),
      passwordRepeat: getPasswordRepeatError(value, password, t),
    }[type] || null);

  // Обрабатываем изменения в полях пароля
  const handleFieldChange = (e, type) => {
    updateState(type, e.target.value);
    updateState(`${type}Error`, null);

    if (type === "passwordRepeat" && password === e.target.value) {
      updateState("isButtonDisabled", false);
    }
  };

  // Обрабатываем событие потери фокуса для полей пароля
  const handleFieldBlur = (e, type) => {
    const error = validateField(type, e.target.value, password);
    updateState(`${type}Error`, error || null);
  };

  // Переключаем видимость пароля
  const handleTogglePasswordVisibility = (type) => {
    updateState(type, !state[type]);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Обрабатывает отправку формы
  const handleSubmit = (event) => {
    // Предотвращает стандартное поведение отправки формы
    event.preventDefault();

    // Инициализирует флаг, отслеживающий валидность формы
    let isValid = true;

    // Валдирует пароль
    const passwordError = getPasswordError(password, t);

    // Валдирует повторный пароль
    const passwordRepeatError = getPasswordRepeatError(
      passwordRepeat,
      password,
      t
    );

    // Если пароль не валиден, обновляет состояние ошибки и флаг валидности
    if (passwordError) {
      updateState("passwordError", passwordError);
      isValid = false;
    } else {
      // Если пароль валиден, обновляет состояние ошибки
      updateState("passwordError", null);
    }

    // Если повторный пароль не валиден, обновляет состояние ошибки и флаг валидности
    if (passwordRepeatError) {
      updateState("passwordRepeatError", passwordRepeatError);
      isValid = false;
    } else {
      // Если повторный пароль валиден, обновляет состояние ошибки
      updateState("passwordRepeatError", null);
    }

    // Если пароль и повторный пароль совпадают и не имеют ошибок, разблокирует кнопку отправки
    if (password === passwordRepeat && !passwordError && !passwordRepeatError) {
      updateState("isButtonDisabled", false);
    } else {
      // Иначе блокирует кнопку отправки
      updateState("isButtonDisabled", true);
    }

    // Если форма не валидна, блокирует кнопку отправки
    if (!isValid) {
      updateState("isButtonDisabled", true);
    } else {
      // Если форма валидна, отправляет форму
      console.log("Form data:", password, passwordRepeat);
      event.target.submit();
    }
  };

  // Массив объектов, описывающих поля ввода пароля и повторного ввода пароля
  const passwordFields = [
    {
      // Первое поле - ввод пароля
      id: "password",
      label: t("forms.password"), // Метка для поля (например, "Пароль")
      type: showPassword ? "text" : "password", // Тип поля (текст или пароль) в зависимости от значения showPassword
      icon: showPassword ? <VisibilityOff /> : <Visibility />, // Иконка для поля (отображение/скрытие пароля)
      onChange: (e) => handleFieldChange(e, "password"), // Обработчик изменения значения поля
      onBlur: (e) => handleFieldBlur(e, "password"), // Обработчик потери фокуса поля
      onFocus: () => updateState("passwordError", null), // Обработчик получения фокуса поля (очищает ошибку)
      error: passwordError, // Ошибка для поля (например, если пароль не соответствует требованиям)
    },
    {
      // Второе поле - повторный ввод пароля
      id: "passwordRepeat",
      label: t("forms.password_repeat"), // Метка для поля (например, "Повторите пароль")
      type: showPasswordRepeat ? "text" : "password", // Тип поля (текст или пароль) в зависимости от значения showPasswordRepeat
      icon: showPasswordRepeat ? <VisibilityOff /> : <Visibility />, // Иконка для поля (отображение/скрытие пароля)
      onChange: (e) => handleFieldChange(e, "passwordRepeat"), // Обработчик изменения значения поля
      onBlur: (e) => handleFieldBlur(e, "passwordRepeat"), // Обработчик потери фокуса поля
      onFocus: () => updateState("passwordRepeatError", null), // Обработчик получения фокуса поля (очищает ошибку)
      error: passwordRepeatError, // Ошибка для поля (например, если пароли не совпадают)
    },
  ];

  const getError = (field) => field.error !== null;

  // Создаем функцию-обработчик клика
  const handleClick = (fieldId) => () =>
    handleTogglePasswordVisibility(
      fieldId === "password" ? "showPassword" : "showPasswordRepeat"
    );

  const fieldsComponents = passwordFields.map((field) => (
    <TextField
      key={field.id}
      error={getError(field)}
      id={field.id}
      label={field.label}
      type={field.type}
      variant="outlined"
      fullWidth
      margin="normal"
      onChange={field.onChange}
      onBlur={field.onBlur}
      onFocus={field.onFocus}
      helperText={field.error}
      InputProps={{
        endAdornment: (
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClick(field.id)}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {field.icon}
          </IconButton>
        ),
      }}
    />
  ));

  return (
    <Box
      width="100%"
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <h1 className={styles.title}>{t("forms.changePassword_title")}</h1>
      {fieldsComponents}
      <BaseButton
        type="submit"
        disabled={isButtonDisabled}
        buttonTranslation="forms.сhange_pass_butt"
        href="/congratulations"
      />
    </Box>
  );
}

export default ChangePasswordForm;
