import React from "react";

import styles from "./index.module.css";

import Invest from "./invest";
import WeAreInNumbers from "./numberCard";
import InvestmentSecurity from "./investCard";
import LineCharts from "./chartsLines";
import UnderstandMarket from "./understandTheMarket";
import HistoryOfTheLeader from "./historyLeader";
import OurPartners from "./partners";

const MainPage = () => {
  return (
    <div className={styles.wrapper}>
      <Invest />
      <WeAreInNumbers />
      <LineCharts />
      <InvestmentSecurity />
      <UnderstandMarket />
      <HistoryOfTheLeader />
      <OurPartners />
    </div>
  );
};

export default MainPage;
