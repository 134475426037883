import { Box } from "@mui/material";

const boxStyles = {
  my: 4,
  display: "flex",
  alignItems: "center",
  gap: 4,
  sx: {
    background: "linear-gradient(180deg, #FFFFFF 0%, #ECEFF1 100%)",
    border: `1px solid  #F7EF8A`,
    borderRadius: "10px",
    boxShadow: "1px 2px 20px 0px #084fbd40",
    px: "40px",
  },
};

export function BoxComponent(props) {
  return <Box {...boxStyles} {...props} />;
}
