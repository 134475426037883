export const faqItems1 = [
  { id: 1, title: "faq.accordion_1", content: "faq.lorem_ipsum_1" },
  { id: 2, title: "faq.accordion_2", content: "faq.lorem_ipsum_2" },
  { id: 3, title: "faq.accordion_3", content: "faq.lorem_ipsum_3" },
  { id: 4, title: "faq.accordion_4", content: "faq.lorem_ipsum_4" },
  { id: 5, title: "faq.accordion_5", content: "faq.lorem_ipsum_5" },
  { id: 6, title: "faq.accordion_6", content: "faq.lorem_ipsum_6" },
  { id: 7, title: "faq.accordion_7", content: "faq.lorem_ipsum_7" },
  { id: 8, title: "faq.accordion_8", content: "faq.lorem_ipsum_8" },
  { id: 9, title: "faq.accordion_9", content: "faq.lorem_ipsum_9" },
  { id: 10, title: "faq.accordion_10", content: "faq.lorem_ipsum_10" },
];

export const faqItems2 = [
  { id: 11, title: "faq.accordion_1", content: "faq.lorem_ipsum_1" },
  { id: 12, title: "faq.accordion_2", content: "faq.lorem_ipsum_2" },
];
