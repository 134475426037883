import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import styles from "./index.module.css";

import { BoxComponent } from "../../additionalComponents/boxComponent";
import BaseButton from "../../additionalComponents/button";
import useTitle from "../../additionalComponents/titlePage";
import {
  getEmailError,
  getPasswordError,
} from "../../validatorComponents/getErrors";

import { Box, TextField, Checkbox, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Это основной компонент формы входа
function LoginForm() {
  return (
    <div className={styles.wrapper}>
      <BoxComponent width={428} height={600}>
        <Login />
      </BoxComponent>
    </div>
  );
}

// Это функция, возвращающая контент формы входа
function Login() {
  const { t } = useTranslation();
  useTitle(t("title.loginForms"));

  //Мы создаем объект state с начальными значениями, которые будут изменяться в зависимости от действий пользователя.
  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    rememberMe: false,
    emailError: null,
    passwordError: null,
  });

  // Разбирает объект состояния на отдельные переменные
  const {
    email,
    password,
    showPassword,
    rememberMe,
    emailError,
    passwordError,
  } = state;

  // Обновляет состояние, объединяя новые значения с предыдущим состоянием
  const updateState = (key, value) => {
    setState((prevState) => ({ ...prevState, [key]: value }));
  };

  // Обрабатываем изменения в полях электронной почты или пароля
  const handleFieldChange = (e, type) => {
    updateState(type, e.target.value);
    updateState(`${type}Error`, null);
  };

  // Обрабатываем событие потери фокуса для полей электронной почты или пароля
  const handleFieldBlur = (e, type) => {
    if (type === "password") {
      const passwordError = getPasswordError(e.target.value, t);
      updateState("passwordError", passwordError);
    } else if (type === "email") {
      const emailError = getEmailError(e.target.value, t);
      updateState("emailError", emailError);
    }
  };

  // Переключаем видимость пароля
  const handleClickShowPassword = () =>
    updateState("showPassword", !showPassword);

  // Обрабатывает нажатие на переключатель видимости пароля
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Обрабатываем изменения в чекбоксе "Запомнить меня"
  const handleRememberMeChange = (event) => {
    updateState("rememberMe", event.target.checked);
  };

  const handleSubmit = (event) => {
    // Предотвратить стандартное поведение отправки формы
    event.preventDefault();

    // Инициализировать переменную для отслеживания валидности формы
    let isValid = true;

    // Проверить поле электронной почты
    // const emailError = getPasswordEmail(email, t);
    const emailError = getEmailError(email, t);

    // Проверить поле пароля
    const passwordError = getPasswordError(password, t);

    // Если электронная почта невалидна, обновить состояние и установить isValid в false
    if (emailError) {
      updateState("emailError", emailError);
      isValid = false;
    }
    // Если пароль невалиден, обновить состояние и установить isValid в false
    if (passwordError) {
      updateState("passwordError", passwordError);
      isValid = false;
    }
    // Если форма валидна, вывести сообщение об успехе в консоль
    if (isValid) {
      console.log("Форма валидна, отправка...", email, password, rememberMe);
    }
  };

  const handleEmail = {
    // Обработчик события изменения поля электронной почты
    change: (e) => handleFieldChange(e, "email"),
    // Обработчик события потери фокуса поля электронной почты
    blur: (e) => handleFieldBlur(e, "email"),
    // Обработчик события получения фокуса поля электронной почты (в данном случае пустой)
    focus: () => {},
  };

  const handlePassword = {
    // Обработчик события изменения поля пароля
    change: (e) => handleFieldChange(e, "password"),
    // Обработчик события потери фокуса поля пароля
    blur: (e) => handleFieldBlur(e, "password"),
    // Обработчик события получения фокуса поля пароля (в данном случае пустой)
    focus: () => {},
  };

  const emError = emailError !== null;
  const passError = passwordError !== null;
  const getPasswordType = showPassword ? "text" : "password";

  const passwordIcon = showPassword ? <VisibilityOff /> : <Visibility />;

  return (
    <Box
      width="100%"
      component="form"
      noValidate
      autoComplete="on"
      onSubmit={handleSubmit}
    >
      <h1 className={styles.title}>{t("forms.title")}</h1>

      <TextField
        error={emError}
        id="email"
        label={t("forms.email")}
        variant="outlined"
        fullWidth
        value={email}
        onChange={handleEmail.change}
        onBlur={handleEmail.blur}
        onFocus={handleEmail.focus}
        margin="normal"
        helperText={emailError}
      />

      <TextField
        error={passError}
        id="password"
        label={t("forms.password")}
        type={getPasswordType}
        variant="outlined"
        fullWidth
        value={password}
        onChange={handlePassword.change}
        onBlur={handlePassword.blur}
        onFocus={handlePassword.focus}
        margin="normal"
        helperText={passwordError}
        InputProps={{
          endAdornment: (
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {passwordIcon}
            </IconButton>
          ),
        }}
      />

      <Box>
        <ForgotPasswordLink />
        <div className={styles.checkbox}>
          <Checkbox
            id="rememberMe"
            checked={rememberMe}
            onChange={handleRememberMeChange}
            sx={{
              padding: 0,
            }}
          ></Checkbox>
          <span className={styles.inscriptionSpam}>
            {t("forms.remember_me")}
          </span>
        </div>
      </Box>
      <BaseButton type="submit" buttonTranslation="forms.sing_in" href="/" />
      <OrText />
      <GoogleSignInButton />
      <LoginAccountLink />
    </Box>
  );
}

// Это функция, возвращающая ссылку на страницу восстановления пароля
function ForgotPasswordLink() {
  const { t } = useTranslation();
  return (
    <div className={styles.forgot_password}>
      <Link href="/">{t("forms.forgot_password")}</Link>
    </div>
  );
}

// Это функция, возвращающая текст "или"
function OrText() {
  const { t } = useTranslation();
  return <div className={styles.loginOr}>{t("forms.or")}</div>;
}

// Это функция, возвращающая кнопку входа через Google
function GoogleSignInButton() {
  return (
    <div className={styles.google}>
      <a href="/">
        <img src="./icons_svg/devicon_google.svg" alt="Google" />
      </a>
    </div>
  );
}

// Это функция, возвращающая ссылку на страницу создания аккаунта
function LoginAccountLink() {
  const { t } = useTranslation();
  return (
    <div className={styles.loginCreateAccount}>
      <div>{t("forms.no_account")}</div>
      <div className={styles.accountLink}>
        <Link to="/singup">{t("forms.create_account")}</Link>
      </div>
    </div>
  );
}

export default LoginForm;
