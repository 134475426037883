import React from "react";
import { withTranslation } from "react-i18next";

import { Button } from "@mui/material";

const BaseButton = ({ t, buttonTranslation, onClick, disabled, type }) => {
  return (
    <Button
      variant="contained"
      width={104}
      sx={{
        marginTop: 4,
        backgroundColor: "var(--primary-color)",
        textTransform: "uppercase",
      }}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {t(buttonTranslation)}
    </Button>
  );
};

export default withTranslation()(BaseButton);
