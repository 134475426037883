import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

import Box from "@mui/material/Box";
import { LineChart } from "@mui/x-charts/LineChart";

import moment from "moment";

const AverageCard = ({ num, description, index, activeIndex, data }) => {
  const { t } = useTranslation();

  return (
    <Box
      className={styles.averageCard}
      sx={{
        transform: `translateY(${index === activeIndex ? 0 : "50%"})`,
        opacity: index === activeIndex ? 1 : 0,
      }}
    >
      <div className={styles.averageNumber}>{num}</div>
      <div className={styles.averageDescription}>{t(description)}</div>
      <LineChart
        xAxis={[
          {
            id: "Months",
            data: data.xAxis,
            scaleType: "time",
            valueFormatter: (date) => {
              return moment(date).format("MM/YYYY"); // Форматируем дату с помощью Moment.js
            },
          },
        ]}
        series={[
          {
            id: "Series",
            data: data.series,
            color: data.color,
            showMark: true,
          },
        ]}
        width={500}
        height={250}
        margin={{ left: 60, top: 10, right: 20 }}
      />
    </Box>
  );
};

// Определяем массив карточек
let cards = [
  {
    num: "483$",
    description: "mainPage.card1",
    data: {
      xAxis: [],
      series: [],
      color: "var(--primary-color)",
    },
  },
  {
    num: "323$",
    description: "mainPage.card2",
    data: {
      xAxis: [],
      series: [],
      color: "var(--card2-color)",
    },
  },
  {
    num: "23",
    description: "mainPage.card3",
    data: {
      xAxis: [],
      series: [],
      color: "var(--card3-color)",
    },
  },
];

// Функция генерации данных
const generateChartData = (cards) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  for (let i = 0; i < 12; i++) {
    const date = new Date(currentYear, currentMonth - i, 1);
    cards.forEach((card) => {
      card.data.xAxis.push(date);
      card.data.series.push(Math.random() * 10 - 5);
    });
  }

  return cards;
};

// Генерируем данные для графика
cards = generateChartData(cards);

// Определяем компонент RenderCards
const RenderCards = ({ cards, activeCardIndex }) => {
  return (
    <>
      {cards.map((card, index) => (
        <AverageCard
          key={index}
          num={card.num}
          description={card.description}
          index={index}
          activeIndex={activeCardIndex}
          data={card.data}
        />
      ))}
    </>
  );
};

// Определяем компонент Slider
const CardRotator = () => {
  const [activeCardIndex, setActiveCardIndex] = React.useState(0);

  React.useEffect(() => {
    // Создаем интервал, который меняет активный индекс карточки каждые 3 секунды
    const intervalId = setInterval(() => {
      setActiveCardIndex((prevActiveCardIndex) => {
        // Если активный индекс карточки равен последнему индексу в массиве,
        // то устанавливаем активный индекс на 0
        if (prevActiveCardIndex === cards.length - 1) {
          return 0;
        }
        // Иначе, увеличиваем активный индекс на 1
        return prevActiveCardIndex + 1;
      });
    }, 7000);

    // Очищаем интервал, когда компонент будет размонтирован
    return () => clearInterval(intervalId);
  }, []);

  //Возвращаем компонент Slider
  return (
    <div style={{ position: "relative", width: 513, height: 365 }}>
      <RenderCards cards={cards} activeCardIndex={activeCardIndex} />
    </div>
  );
};

export default CardRotator;
