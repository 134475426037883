// Импортируем необходимые библиотеки и компоненты
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

import { generateData } from "../../additionalComponents/dataGenerator"; // функция для генерации данных
import { LineChart, lineElementClasses } from "@mui/x-charts/LineChart"; // компонент для отображения линейного графика

import moment from "moment"; // библиотека для работы с датами

// Основной компонент BasicLineChart
const BasicLineChart = () => {
  // Получаем функцию для перевода текста
  const { t } = useTranslation();

  // Инициализируем состояния для загрузки данных и хранения данных графика
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({ months: [], data: {} });

  // Эффект для загрузки данных и устан��вки состояния загрузки
  useEffect(() => {
    // Устанавливаем таймер для имитации загрузки данных
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Генерируем данные для графика
    const { months, data } = generateData();
    setChartData({ months, data });

    // Очищаем таймер при размонтировании компонента
    return () => clearTimeout(timer);
  }, []);

  // Настройки стилей для графика
  const chartStyles = {
    root: {
      strokeWidth: 4,
    },
    series: {
      seriesA: {
        stroke: "var(--primary-color)",
      },
      seriesB: {
        stroke: "var(--card2-color)",
      },
      seriesC: {
        stroke: "var(--card3-color)",
      },
    },
  };

  // Настройки оси X
  const xAxisSettings = {
    id: "Months",
    data: chartData.months,
    scaleType: "time",
    valueFormatter: (date) => moment(date).format("MM/YYYY"),
  };

  // Данные для иконок
  const icons = [
    {
      src: "./icons_svg/tradeNestLine.svg",
      alt: "/",
      text: "mainPage.seriesA",
    },
    { src: "./icons_svg/S&P500Line.svg", alt: "/", text: "mainPage.seriesB" },
    { src: "./icons_svg/bitcoinLine.svg", alt: "/", text: "mainPage.seriesC" },
  ];

  // Отображение иконок
  const iconsMap = icons.map((icon, index) => (
    <div key={index} className={styles.containarIconsImg}>
      <img src={icon.src} alt={icon.alt} />
      <span>{t(icon.text)}</span>
    </div>
  ));

  // Компонент для отображения графика
  const RenderChart = () => {
    // Если данные еще не загружены, отображаем загрузчик
    if (isLoading) {
      return (
        <div className={styles.loaderContainer}>
          <div className={styles.loader} />
          <div className={styles.loaderText}>{t("mainPage.loading")}</div>
        </div>
      );
    }

    // Настройки стилей для графика
    const chartSx = {
      [`& .${lineElementClasses.root}`]: chartStyles.root,
      "& .MuiLineElement-series-seriesA": chartStyles.series.seriesA,
      "& .MuiMarkElement-series-seriesA": chartStyles.series.seriesA,
      "& .MuiLineElement-series-seriesB": chartStyles.series.seriesB,
      "& .MuiMarkElement-series-seriesB": chartStyles.series.seriesB,
      "& .MuiLineElement-series-seriesC": chartStyles.series.seriesC,
      "& .MuiMarkElement-series-seriesC": chartStyles.series.seriesC,
    };

    // Настройки оси X
    const chartXAxis = [xAxisSettings];

    // Данные для графика
    const chartSeries = [
      {
        id: "seriesA",
        data: chartData.data.seriesA,
        showMark: true,
      },
      {
        id: "seriesB",
        data: chartData.data.seriesB,
        showMark: true,
      },
      {
        id: "seriesC",
        data: chartData.data.seriesC,
        showMark: true,
      },
    ];

    // Отображение графика
    return (
      <>
        <div className={styles.containarIcons}>{iconsMap}</div>
        <div className={styles.lineChart}>
          <LineChart
            sx={chartSx}
            xAxis={chartXAxis}
            series={chartSeries}
            width={1200}
            height={300}
          />
        </div>
      </>
    );
  };

  // Основной компонент для отображения графика
  return (
    <div className={styles.containarChartLines}>
      <h2 className={styles.chartsLinesTitle}>{t("mainPage.chartsLines")}</h2>
      <div className={styles.containarLineChart}>
        <RenderChart />
      </div>
    </div>
  );
};

export default BasicLineChart;
