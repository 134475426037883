// useAuth.js
import { useState, useEffect } from "react";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loginState = sessionStorage.getItem("isLoggedIn");
    if (loginState) {
      setIsLoggedIn(JSON.parse(loginState));
    }
  }, []);

  return isLoggedIn;
};

export default useAuth;
