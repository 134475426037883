import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

const partnerImages = [
  { src: "./icons_svg/partner1.svg", alt: "/" },
  { src: "./icons_svg/partner2.svg", alt: "/" },
  { src: "./icons_svg/partner3.svg", alt: "/" },
];

const renderPartnerImages = partnerImages.map((image, index) => (
  <div key={index}>
    <img src={image.src} alt={image.alt} />
  </div>
));

function OurPartners() {
  const { t } = useTranslation();

  return (
    <div className={styles.containerPartners}>
      <h2>{t("mainPage.our_partners")}</h2>
      <div className={styles.containerPartnersImg}>{renderPartnerImages}</div>
    </div>
  );
}

export default OurPartners;
