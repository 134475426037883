export const paymentSystems = [
  { src: "./icons_svg/visa.svg", alt: "Visa" },
  { src: "./icons_svg/mastercard.svg", alt: "Mastercard" },
  { src: "./icons_svg/bitcoin.svg", alt: "Bitcoin" },
];

export const socialLinks = [
  { src: "./icons_svg/telegram.svg", alt: "Telegram" },
  { src: "./icons_svg/tiktok.svg", alt: "TikTok" },
  { src: "./icons_svg/instagram.svg", alt: "Instagram" },
];

export const linksNav = [
  { text: "appFooter.fund", href: "/fund" },
  { text: "appFooter.learning", href: "/learning" },
  { text: "appFooter.faq", href: "/faq" },
  { text: "appFooter.blog", href: "/blog" },
  { text: "appFooter.legal", href: "/legal" },
];

export const links = [
  { text: "appFooter.personal", href: "/personal" },
  { text: "appFooter.partners", href: "/partners" },
  { text: "appFooter.program", href: "/program" },
  { text: "appFooter.privacy", href: "/privacy" },
];
