import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./index.module.css";

import Navigation from "./Navigation";
import LanguageSelect from "./LanguageSelect";

const Header = () => {
  const { t } = useTranslation();

  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div className={styles.containerLogoNav}>
          <div className={styles.headerLogo}>
            <a href="/">
              <img src="./icons_svg/logo.svg" alt="Trade Nest" />
            </a>
          </div>
          <Navigation />
        </div>
        <div className={styles.headerActions}>
          <LanguageSelect />
          <Link to="/login">
            <button className={styles.headerProfile}>
              <span>{t("appHeader.login")}</span>
            </button>
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
