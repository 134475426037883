import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./index.module.css";

const navigationElements = [
  { id: 1, label: "appHeader.fund", link: "/fund" },
  { id: 2, label: "appHeader.learning", link: "/learning" },
  { id: 3, label: "appHeader.faq", link: "/faq" },
  { id: 4, label: "appHeader.blog", link: "/blog" },
];

const Navigation = () => {
  const { t } = useTranslation();

  const navigationLinks = navigationElements.map((element) => (
    <li className={styles.headerNavLink} key={element.id}>
      <a href={element.link || "/"}>{t(element.label)}</a>
    </li>
  ));

  return (
    <div className={styles.headerNavLink}>
      <ul>{navigationLinks}</ul>
    </div>
  );
};

export default Navigation;
